
import { defineComponent } from 'vue'
import HeadingComponent from '@/components/shared/HeadingComponent.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  components:{
      HeadingComponent,
      Footer
    }
})
